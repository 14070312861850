<template>
    <div class="card">
        <div style="background: #DBE7F2" class="p-2">
           <TitleButton
               btnTitle="Add New"
               :showBtn="false"
               :showAddNew="true"
               title="Bill Payment List"
               @onClickAddNewButton="onClickAddNewButton"
           />
            <div class="row match-height mt-1">
                <div class="col-12">
                    <DateQuerySetter
                        @onClickGo="getPayBillList"
                    />
                </div>
            </div>
        </div>
        
        <div class="card p-2" style="min-height: 200px">
                <div class="table-responsive">
                    <table class="table">
                        <thead>
                        <tr>
                            <th>Voucher Date</th>
                            <!-- <th>Voucher No</th> -->
                            <th>Txn Ref No</th>
                            <th>Party Name</th>
                            <th>Paid Amount</th>
                            <th>Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(item, i) in payBillList" :key="i">
<!--                            <td>-->
<!--                                <span class="fw-bold">{{ item.date }}</span>-->
<!--                            </td>-->
                            <td> {{item.payment_date}} </td>
                            <!-- <td> --- </td> -->
                            <td> {{ item.voucher_no }} </td>
                            <td>{{ item.party }}</td>
                            <td> {{ commaFormat(item.total_paid_amount) }} </td>
                            <td @click="goToEdit(item.id)">
                                <span style="cursor: pointer">View / Edit</span>
                            </td>
                        </tr>
                    
                        </tbody>
                    </table>
                    <p v-if="!payBillList.length" class="text-center mt-5">No data</p>
                </div>
            </div>
        
        <div class="mb-2"></div>
        <div class="px-2 position-absolute bottom-0">
            <Pagination
                :offset="offset"
                @onPageChange="onPageChange"
                ref="pagination"
            />
        </div>
        <Loader v-if="loading"/>
    
    </div>
</template>

<script>
import handlePayBill from '@/services/modules/payBill'
import {inject} from 'vue'
import {mapMutations, useStore} from 'vuex'

import TitleButton from '@/components/atom/TitleButton'
import DateQuerySetter from '@/components/atom/DateQuerySetter'
import ListTable from '@/components/molecule/journal/ListTable'
import Pagination from '@/components/atom/Pagination'
import Loader from '@/components/atom/LoaderComponent'
import figureFormatter from '@/services/utils/figureFormatter'

export default {
    name: 'pay-bill-list',
    
    data: () => ({
        offset: 20,
        payBillList : {}
    }),
    
    components: {
        TitleButton,
        DateQuerySetter,
        ListTable,
        Pagination,
        Loader
    },
    
    computed: {
        companyId() {
            return this.$route.params.companyId
        },
        start() {
            return this.$route.query.start
        },
        end() {
            return this.$route.query.end
        },
        page() {
            return this.$route.query.page
        },
    },
    
    methods: {
        ...mapMutations({
            setPaginationData: 'setPaginationData',
            resetPagination: 'resetPagination'
        }),

        onClickAddNewButton() {
            this.$router.push({name: 'purchase-bill-pay', params: this.$route.params, query: this.$route.query})
        },
        
        onPageChange(page) {
            let routeQuery = Object.assign({}, this.$route.query)
            routeQuery.page = page
            this.$router.push({path: this.$route.path, query: routeQuery})
            setTimeout(() => {
                this.getPayBillList()
            },100)
        },
    
        async getPayBillList() {
            const query = this.getQuery()
            // return;
            try {
                this.loading = true
                let res = await this.fetchPayBillList(query)
                if(!res.status) {
                    this.payBillList = {}
                    this.resetPagination()
                    // this.showError(res.message)
                }
                if(res.status) {
                    if(res.data.data.length > 0) {
                        this.setPagination(res.data)
                        this.payBillList = res.data.data;
                    }
                }
            } catch (err) {
                if(!err.response) {
                    this.showError('Something is wrong. Check your connectivity!!')
                }
                if(err.response) {
                    this.showError(err.response.message)
                }
            } finally {
                this.loading = false
            }
        },
        
        setPagination(data) {
            this.setPaginationData({
                records: data.total,
                perPage: data.per_page,
                currentPage: data.current_page,
            })
        },
        
        getQuery () {
            let query = '?company_id=' + this.companyId
            if(!this.start && !this.end) return query
            if(this.start) query += '&start_date='+this.start
            if(this.end) query += '&end_date='+this.end
            query += '&offset='+this.offset
            if(this.page) query += '&page='+this.page
            return query
        },
        goToEdit(id) {
            this.$router.push({name: 'pay-bill-edit',
                params: {
                    companyId: this.$route.params.companyId,
                    moduleId: this.$route.params.moduleId,
                    menuId: this.$route.params.menuId,
                    pageId: this.$route.params.pageId,
                    paymentId: id
                },
                query: {
                    start: this.$route.query.start,
                    end:  this.$route.query.end
                }

            })
        }
    },
    
    setup() {
        const showError =  inject('showError');
        const showSuccess =  inject('showSuccess');
        const store = useStore()
        const {commaFormat} = figureFormatter ()
        
        const {
            fetchPayBillList,
            loading
        } = handlePayBill()
        
        return {
            fetchPayBillList,
            loading,
            showError,
            commaFormat,
            showSuccess
        }
    },
    
    mounted() {
        this.getPayBillList()
    }
}
</script>
